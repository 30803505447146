$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-bg: #ddf1f9;
$body-color: #707070;
$headings-color: #555;
$headings-font-family: $font-family-sans-serif;

$primary: #689d48;

$link-color: $primary;
$link-hover-color: $primary;

$navbar-padding-y: 0;
$navbar-nav-link-padding-x: 1rem;

$nav-tabs-link-active-bg: $primary;

$navbar-light-color:                $body-color;
$navbar-light-hover-color:          #fff;
$navbar-light-active-color:         #fff;
$navbar-light-disabled-color:       rgba(#fff, .3);

$navbar-link-hover-bg: $primary;
$navbar-link-active-bg: $primary;

$hamburger-color: #000;

$story-headings-color: #000;

$ads-backdrop-color-1: #c2e6f4;

@import "../../fonts/gochi-hand";
@import "../../fonts/open-sans";
@import "../../magazine";

.navbar-top {
  background-image: url(../../../img/meineorte/bg_header_1200.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 15px 0;
}

.navbar {
  border-bottom: 1px solid #e9e9e9;

  .nav-link {
    color: white;
    font-family: 'Gochi Hand', cursive;
    font-weight: $font-weight-lighter;

    @include hover-focus {
      background-color: $nav-tabs-link-active-bg;
    }
  }

  .pur-mgmt .nav-item {
    background-color: #fff;
  }
}

.navbar.navbar-expand  {
  .nav-item {
    @include nav-item-separator(#fff, $navbar-link-font-size);
  }
}

.navbar.navbar-expand-md {
  @include media-breakpoint-up(md) {
    border-top: none;
  }
}

.brand-logo {
  text-align: center;
}
@include media-breakpoint-up(md) {
  .brand-logo img {
    height: 80px;
    filter: drop-shadow(1px 1px 0 #707070);
  }
}

.sidebar .card.filled {
  background-color: #E9E9E9;
}

.section-caption {
  color: $story-headings-color;
  font-weight: $font-weight-normal;
}


.article-head p {
  font-weight: $font-weight-normal;
}
.article-head h1,
.article-story h3, .article-story h4 {
  color: $primary;
  font-weight: $font-weight-normal;
}

.navbar.navbar-expand .nav-item+.nav-item::after {
  display: none;
}
